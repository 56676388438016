@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
    url('./fonts/Roboto-Regular.ttf') format('truetype');
}

.app {
    min-height: 100vh;
    font-family: Roboto;
    background-color: rgb(250, 250, 250);
}

.app .container-fluid{
    padding-top: 2em;
}

.app h2{
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 1.5em;
}

main a:hover {
    color: rgba(0, 0, 0, 0.37);
}

.app a{
    color: rgba(0, 0, 0, 0.77);
    text-decoration: none;
}

.spinner{
    text-align: center;
}

.tab-content a{
    display: block;
}

.app .table{
    cursor: pointer;
    vertical-align: middle;
}

.app .row{
    margin: 0;
}

.header h1 {
    padding: 0.25em 0;
    margin: 0;
    text-transform: uppercase;
}

.app .header {
    text-align: center;
    padding: 0;
    /*background: linear-gradient(138deg, rgb(196, 226, 235) 35%, rgba(189,103,103,0.26943277310924374) 100%);;*/
    background-color: rgb(203 219 223);
    -webkit-box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
}

.header .navbar {
    position: sticky;
    display: flex;
    list-style: none;
    justify-content: space-between;;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 1.2em;
    padding: 1em 10px;
    margin: 0;
}

.header .list-group{
    justify-content: flex-end;
}

.header .list-group-item{
    background-color: transparent;
    border: none;
}

.header .list-group-item img{
    height: 1.1em;
    margin-bottom: 3px;
}

.navbar a {
    padding: 0 0.5em;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.77);
}

.navbar-brand{
    display: flex;
}

.navbar .navbar-nav a{
    position: relative;
}

.navbar .navbar-nav a::after{
    position: absolute;
    content: '';
    bottom: 0;
    display: block;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    transition: .4s;
    background: rgba(0, 0, 0, 0.37);;
}

.navbar .navbar-nav a:hover{
    color: rgba(0, 0, 0, 0.37);
}

.navbar .navbar-nav a:hover::after {
    width: 88%;
}

.start-page {
    background: rgb(90, 153, 176);
    background: linear-gradient(138deg, rgba(209,234,244,1) 35%, rgba(189,103,103,0.26943277310924374) 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form {
    padding: 0.5em;
    background-color: rgb(155 189 209);
    color: #fafbff;
    border-radius: 5px;
    font-size: 2em;
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.login-logo{
    margin: 20px 0;
}

.login-form input::-webkit-input-placeholder {
    font-size: 1.2em;
    color: #fafbff;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.login-form input::-moz-placeholder {
    color: #fafbff;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.login-form input:-moz-placeholder {
    color: #fafbff;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.login-form input:-ms-input-placeholder {
    color: #fafbff;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.login-form input:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.login-form input:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.login-form input:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.login-form input:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.login-form button {
    font-size: 0.8em;
    background-color: rgba(0, 90, 255, 0);
    border: none;
}

.login-form button:hover {
    background-color: rgba(82, 123, 125, 0.61);
    border: none;
}

.login-form input {
    outline: none;
    border: none;
    background-color: inherit;
    padding: 3px 9px;
    border-radius: 4px;
    border-bottom: solid 1px rgb(231, 238, 255);
}

.login-form input:focus {
    background-color: rgb(217, 234, 255);
    box-shadow: none;
    border-radius: 5px;
}

.login-form label {
    padding: 3px;
}

.login-wrapper {
    flex-direction: column;
}

i[class^="bi bi-"]::before{
    margin-right: 10px;
    margin-left: 10px;
    font-size: 1em;
}

.header i[class^="bi bi-"]::before{
    margin-right: 10px;
    margin-left: 1px;
    font-size: 1em;
}

.pagination .page-item{
    --bs-pagination-hover-color: #81807f;
}

.page-item.active .page-link{
    background-color: #b4b4b4;
    border-color: #9ca2a7;
}

.contacts-list {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
}

.contacts-list .list-group-item{
    background-color: rgb(236, 242, 255);
}

.contacts-list a{
    text-transform: lowercase;
}

.start-page span {
    min-height: 4em;
    color: #f34e41;
    font-size: 1em;
    font-weight: normal;
    padding: 1em 0;
}

.list-group-item img {
    height: 1.1em;
    margin-bottom: 3px;
}

.enter-btn{
    min-height: 2.2em;
}

.enter-btn button{
    text-transform: uppercase;
}

.enter-btn .spinner-border {
    padding: 0.65em;
    --bs-spinner-width: 0;
    --bs-spinner-height: 0;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.15em;
}

.mainDashBoard {
    width: 100vw;
    height: 100vh;
    background-color: rgba(15, 165, 115, 0.08);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sections {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}

.section-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    flex: 0 0 auto;
    margin: 2em auto;
    padding: 1em;
    font-size: 1.5em;
    width: 28%;
    height: 7em;
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(61, 153, 112, 0.23);
    border-radius: 10px;
    -webkit-box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);
}

.section-item:hover {
    color: rgba(0, 0, 0, 0.86);
    background-color: rgba(61, 153, 112, 0.27);
    transform: scale(1.03);
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.list-wrapper {
    text-align: center;
}

.app .orders-wrapper{
    width: 70%
}

.orders-table {
    font-size: 15px;
    text-align: center;
}

/*.orders-table tr td:first-child,*/
.orders-table tr td:nth-child(2){
    text-align: left;
    padding-left: 15px;
}

.orders-table thead {
    background-color: #5b7a77;
    color: #f4f4f4;
    text-transform: uppercase;
}

.orders-table tbody {
    background-color: #fffbff;
}

.orders-table thead th, .orders-table tbody td {
    padding: 3px;
    vertical-align: middle;
}

.app .info-card{
    margin: 20px 0;
    -webkit-box-shadow: 1px 1px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 1px 1px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 1px 1px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 5px;
    background-color: #f4f4f4;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.app .info-card.show {
    opacity: 1;
}

.info-card h4{
    text-align: center;
    margin: 20px 0;
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: bolder;
}

.info-card p{
    text-align: center;
}

.order-details h2{
    font-size: 1.25em;
    text-transform: uppercase;
    text-align: center;
    font-weight: bolder;
}

.not-ready{
    text-align: center;
}

.info-card .info-table{
    padding: 3px;
    text-align: center;
    font-size: 1em;
}

.info-card .info-table thead{
    background-color: #5b7a77;
    color: #f4f4f4;
    text-transform: uppercase;
    vertical-align: middle;
}

.errorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a612c1;
    text-decoration: none;
    color: rgb(255, 255, 255);
    text-align: center;
    height: 100vh;
    text-transform: uppercase;
}

.errorPage h1 {
    font-size: 12em;
}

.errorPage h2 {
    font-size: 3em;
}

.errorPage a {
    color: #ffffff;
    font-size: 2em;
}

.errorPage a:hover {
    color: #2f4173;
}

.tabs {
    font-weight: bolder;
    font-size: 1.5em;
    background-color: rgba(127, 193, 92, 0.19);
}

.tabs button {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.33);
}

.declarant-wrapper .nav-tabs .nav-link.active {
    background-color: rgba(127, 193, 92, 0.29);
}

.declarant-wrapper .nav-tabs .nav-link:hover, .declarant-wrapper .nav-tabs .nav-link:focus {
    color: rgba(0, 0, 0, 0.78);
}

.pagination {
    justify-content: center;
}

.buch-wrapper{
    height: 100vh;
    background-color: rgba(68, 205, 200, 0.21);
}

.buch-wrapper h6{
    text-align: center;
}

.nav-item{
    font-weight: bold;
}

.agent-name.nav-item{
    color: rgba(55, 113, 122, 0.93);
}

.nav-item a:hover{
    color: #6e7f8d;
}

.nav-pills .nav-link.active{
    --bs-nav-pills-link-active-bg: #6c757d;
}

.app .form-control:focus{
    border-color: #f2f2f2;
    box-shadow: 0 0 0 0.1rem rgba(244, 247, 240, 0.25);
}

.image-container {
    margin-right: 6px;
    opacity: 0.6;
}

.app .contacts a{
    padding-bottom: 5px;
}

.pagination{
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 850px) {
    .app{
        font-size: 10px;
    }
    .orders-table {
        font-size: 10px;
    }
    .app header i[class^="bi bi-"]::before {
        font-size: 15px;
    }
    .app .table-responsive{
        min-width: 46%
    }
    .app .pagination{
        --bs-pagination-font-size: 0.7rem;
    }
    .app .orders-wrapper{
        width: 100%;
        word-break: break-all;
    }
}

@media (max-width: 575px) {
    .app .contacts{
        width: 50%;
        margin: auto;
        text-align: left;
    }

    .app .contacts.--our-contacts{
        text-align: center;
    }
}
